<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_info_detail relative w1239" v-if="dataobj">
			<div class="titlediv">
				<div class="title fontsize24">{{dataobj.title}}</div>
				<div class="timer fontsize14">时间：{{dataobj.releaseTime || dataobj.createDate}}</div>
			</div>
			<div class="data_con">
				<div class="fontsize14" v-html="dataobj.productsBody"></div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			uid:0,//详情
			dataobj:null,
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
			this.$router.go(0);
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('trademarkModule/landmark-products/getById', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
